<template>
  <div class="sld_settle_enterprise">
    <div class="sld_enterprise">
      <h3 class="h3_tit">供应商信息</h3>
      <div class="sld_enterprise_box">
        <!-- 基础信息 start -->
        <div class="information">基础信息</div>
        <div class="basics_info">
          <!-- 企业名称 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>企业名称:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入企业名称"
                v-model="data.vendorExtend.enterpriseName"
                
              />
            </div>
          </div>
          <!-- 企业名称 end -->
          <!-- 企业类型 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>企业类型:</span>
            </div>
            <div class="basics_info_sel sel_info">
              <el-select
                v-model="data.vendorExtend.enterpriseType"
                class="m-2"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <!-- 企业类型 end -->
          <!--  组织代码 start -->
          <div class="basics_info_box" style="width: 100%">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span> 组织代码:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入组织代码"
                v-model="data.vendorExtend.enterpriseCode"
              />
            </div>
          </div>
          <!--  组织代码 end -->
          <!--  企业地址: start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span> 企业地址:</span>
            </div>
            <div class="basics_dizhi">
              <div class="basics_di">
                <el-select
                  v-model="data.vendorExtend.enterpriseProvinceCode"
                  class="m-2"
                  placeholder="省"
                  size="large"
                  @change="adderssd(0, 1)"
                >
                  <el-option
                    v-for="item in areaDataArray"
                    :key="item.regionCode"
                    :label="item.regionName"
                    :value="item.regionCode"
                  />
                </el-select>
              </div>
              <div class="basics_di">
                <el-select
                  v-model="data.vendorExtend.enterpriseCityCode"
                  class="m-2"
                  placeholder="市"
                  size="large"
                  ref="cityFlag_o"
                  @visible-change="addCity($event, 1)"
                  @change="adderssd(1, 1)"
                >
                  <el-option
                    v-for="item in cityList_one.data"
                    :key="item.regionCode"
                    :label="item.regionName"
                    :value="item.regionCode"
                  />
                </el-select>
              </div>
              <el-select
                v-model="data.vendorExtend.enterpriseDistrictCode"
                class="m-2"
                placeholder="县"
                size="large"
                ref="distFlag_o"
                @visible-change="addDistrict($event, 1)"
                @change="adderssd(2, 1)"
              >
                <el-option
                  v-for="item in district_one.data"
                  :key="item.regionCode"
                  :label="item.regionName"
                  :value="item.regionCode"
                />
              </el-select>
            </div>
          </div>
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin"></span>
              <span></span>
            </div>
            <div class="basics_info_te">
              <textarea
                name=""
                id=""
                placeholder="请输入企业详细地址"
                v-model="data.vendorExtend.enterpriseDetailAddress"
                maxlength="50"
              ></textarea>
            </div>
          </div>
          <!-- 企业地址 end -->
          <!--  法人姓名 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>法人姓名:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入法人姓名"
                v-model="data.vendorExtend.enterpriseLegalName"
              />
            </div>
          </div>
          <!--  法人姓名 end -->
          <!--  法人身份证号 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>法人身份证号:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                maxlength="18"
                placeholder="请输入法人身份证号"
                v-model="data.vendorExtend.enterpriseLegalCode"
              />
            </div>
          </div>
          <!--  法人身份证号 end -->
          <!--   主要联系人 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>主要联系人:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入主要联系人"
                v-model="data.vendorExtend.enterpriseContactName"
              />
            </div>
          </div>
          <!--  主要联系人 end -->
          <!--  联系人电话 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>联系人电话:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="number"
                name=""
                id=""
                placeholder="请输入联系人电话"
                maxlength="11"
                v-model="data.contactPhone"
              />
            </div>
          </div>
          <!--  联系人电话 end -->
          <!--  注册资本 start -->
          <div class="basics_info_box" style="width: 100%">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>注册资本:</span>
            </div>
            <div class="basics_info_inp  basics_info_tit_o">
              <input
                type="number"
                name=""
                id=""
                placeholder="请输入注册资本"
                v-model="data.vendorExtend.enterpriseMoney"
                @input="inpinfo"
              />
              <span class="wan">万元</span>
            </div>
          </div>
          <!--  注册资本 end -->
          <!--  注册地址: start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>注册地址:</span>
            </div>
            <div class="basics_dizhi">
              <div class="basics_di">
                <el-select
                  v-model="data.vendorExtend.enterpriseRegisterProvinceCode"
                  class="m-2"
                  placeholder="省"
                  size="large"
                  @change="adderssd(0, 2)"
                >
                  <el-option
                    v-for="item in areaDataArray"
                    :key="item.regionCode"
                    :label="item.regionName"
                    :value="item.regionCode"
                  />
                </el-select>
              </div>
              <div class="basics_di">
                <el-select
                  v-model="data.vendorExtend.enterpriseRegisterCityCode"
                  class="m-2"
                  placeholder="市"
                  size="large"
                  ref="cityFlag_t"
                  @visible-change="addCity($event, 2)"
                  @change="adderssd(1, 2)"
                >
                  <el-option
                    v-for="item in cityList_two.data"
                    :key="item.regionCode"
                    :label="item.regionName"
                    :value="item.regionCode"
                  />
                </el-select>
              </div>
              <el-select
                v-model="data.vendorExtend.enterpriseRegisterDistrictCode"
                class="m-2"
                placeholder="县"
                size="large"
                ref="distFlag_t"
                @visible-change="addDistrict($event, 2)"
                @change="adderssd(2, 2)"
              >
                <el-option
                  v-for="item in district_two.data"
                  :key="item.regionCode"
                  :label="item.regionName"
                  :value="item.regionCode"
                />
              </el-select>
            </div>
          </div>
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin"></span>
              <span></span>
            </div>
            <div class="basics_info_te">
              <textarea
                name=""
                id=""
                placeholder="请输入注册详细地址"
                v-model="data.vendorExtend.enterpriseRegisterDetailAddress"
                maxlength="50"
              ></textarea>
            </div>
          </div>
          <!-- 注册地址 end -->
          <!--  经营范围 start -->
          <div class="basics_info_box" style="width: 100%">
            <div class="basics_info_tit" style="height: 100%">
              <span class="xin">*</span>
              <span>经营范围:</span>
            </div>
            <div class="basics_info_te">
              <textarea
                name=""
                id=""
                placeholder="请输入经营范围"
                v-model="data.vendorExtend.enterpriseRange"
                maxlength="50"
              ></textarea>
            </div>
          </div>
          <!--  经营范围 end -->
        </div>
        <!-- 基础信息 end -->
        <!-- 银行信息 start -->
        <div class="information">银行信息</div>
        <div class="bank_info">
          <!-- 开户行名称 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>开户行名称:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入开户行名称"
                v-model="data.vendorExtend.enterpriseBankName"
              />
            </div>
          </div>
          <!-- 开户行名称 end -->
          <!-- 开户行账号 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>开户行账号:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                placeholder="请输入开户行账号"
                v-model="data.vendorExtend.enterpriseBankAccount"
              />
            </div>
          </div>
          <!--  开户行账号 end -->
          <!-- 开户行所在地 start -->
          <div class="basics_info_box" style="width: 100%">
            <div class="basics_info_tit" style="height: 100%">
              <span class="xin">*</span>
              <span>开户行所在地:</span>
            </div>
            <div class="basics_info_te">
              <textarea
                name=""
                id=""
                placeholder="请输入开户行所在地"
                v-model="data.vendorExtend.enterpriseBankOpenAddress"
                maxlength="70"
              ></textarea>
            </div>
          </div>
          <!--  开户行所在地 end -->
          <!-- 开票类型 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>开票类型:</span>
            </div>
            <div class="basics_info_sel sel_info">
              <el-select
                v-model="data.vendorExtend.enterpriseInvoiceType"
                class="m-2"
                placeholder="请选择开票类型"
                size="large"
              >
                <el-option
                  v-for="item in invoiceType"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <!-- 开票类型 end -->
          <!--税率: start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>税率:</span>
            </div>
            <div class="basics_info_inp">
              <input
                type="number"
                name=""
                id=""
                placeholder="请输入税率"
                style="padding-right: 30px"
                v-model="data.vendorExtend.enterpriseRate"
              />
              <p class="basics_bai">%</p>
            </div>
          </div>
          <!--  税率 end -->
        </div>
        <!-- 银行信息 end -->
        <!-- 资质信息 start -->
        <div class="information">资质信息</div>
        <div class="fications">
          <!-- 营业执照 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>营业执照:</span>
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseImg"
                  @click="uploadFile('enterpriseImg')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img($event, 'enterpriseImg', 'enterpriseImgUrl')
                    "
                    ref="uploadFileDOM"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div class="upload_img" v-if="data.vendorExtend.enterpriseImg">
                  <img :src="enterImg.enterpriseImgUrl" alt="" />
                </div>
                <div class="upload_mask" v-if="data.vendorExtend.enterpriseImg">
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="closeImg('enterpriseImg', 'enterpriseImgUrl')"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 营业执照 end -->
          <!-- 法人身份证 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span> 法人身份证:</span><br />
              <span>(正反面)</span>
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseLegalCodeImgFront"
                  @click="uploadFile('enterpriseLegalCodeImgFront')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseLegalCodeImgFront',
                        'enterpriseLegalCodeImgFrontUrl'
                      )
                    "
                    ref="uploadFileDOMa"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseLegalCodeImgFront"
                >
                  <img :src="enterImg.enterpriseLegalCodeImgFrontUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseLegalCodeImgFront"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseLegalCodeImgFront',
                        'enterpriseLegalCodeImgFrontUrl'
                      )
                    "
                  />
                </div>
                <div class="upload_zheng">正面</div>
              </div>
              <div class="info_upload" style="margin-left: 20px">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseLegalCodeImgBack"
                  @click="uploadFile('enterpriseLegalCodeImgBack')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseLegalCodeImgBack',
                        'enterpriseLegalCodeImgBackUrl'
                      )
                    "
                    ref="uploadFileDOMb"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseLegalCodeImgBack"
                >
                  <img :src="enterImg.enterpriseLegalCodeImgBackUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseLegalCodeImgBack"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseLegalCodeImgBack',
                        'enterpriseLegalCodeImgBackUrl'
                      )
                    "
                  />
                </div>
                <div class="upload_zheng">反面</div>
              </div>
            </div>
          </div>
          <!-- 法人身份证 end -->
          <!-- 开户许可证 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>开户许可证:</span><br />
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseOpenAccountImg"
                  @click="uploadFile('enterpriseOpenAccountImg')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseOpenAccountImg',
                        'enterpriseOpenAccountImgUrl'
                      )
                    "
                    ref="uploadFileDOMc"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseOpenAccountImg"
                >
                  <img :src="enterImg.enterpriseOpenAccountImgUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseOpenAccountImg"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseOpenAccountImg',
                        'enterpriseOpenAccountImgUrl'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 开户许可证 end -->
          <!-- 质检报告 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>质检报告:</span><br />
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseQualityImg"
                  @click="uploadFile('enterpriseQualityImg')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseQualityImg',
                        'enterpriseQualityImgUrl'
                      )
                    "
                    ref="uploadFileDOMd"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseQualityImg"
                >
                  <img :src="enterImg.enterpriseQualityImgUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseQualityImg"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseQualityImg',
                        'enterpriseQualityImgUrl'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 质检报告 end -->
          <!-- 生产许可证 start -->
          <div class="basics_info_box">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>生产许可证:</span><br />
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseProduceImg"
                  @click="uploadFile('enterpriseProduceImg')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseProduceImg',
                        'enterpriseProduceImgUrl'
                      )
                    "
                    ref="uploadFileDOMe"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseProduceImg"
                >
                  <img :src="enterImg.enterpriseProduceImgUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseProduceImg"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseProduceImg',
                        'enterpriseProduceImgUrl'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 生产许可证 end -->
          <!-- 商标注册证 start -->
          <div class="basics_info_box" style="margin-right: 18px">
            <div class="basics_info_tit">
              <span class="xin">*</span>
              <span>商标注册证:</span><br />
            </div>
            <div class="basics_info_upl">
              <div class="info_upload">
                <div
                  class="upload_box"
                  v-if="!data.vendorExtend.enterpriseLogoRegisterImg"
                  @click="uploadFile('enterpriseLogoRegisterImg')"
                >
                  <input
                    id="file"
                    class="filepath"
                    @change="
                      upload_img(
                        $event,
                        'enterpriseLogoRegisterImg',
                        'enterpriseLogoRegisterImgUrl'
                      )
                    "
                    ref="uploadFileDOMf"
                    type="file"
                    name="image"
                    size="1"
                    style="display: none"
                    accept="image/gif,image/jpeg,image/gif,image/png"
                  />
                  <img src="../../../assets/up_jia.png" alt="" class="up_jia" />
                  <p>上传照片</p>
                </div>
                <div
                  class="upload_img"
                  v-if="data.vendorExtend.enterpriseLogoRegisterImg"
                >
                  <img :src="enterImg.enterpriseLogoRegisterImgUrl" alt="" />
                </div>
                <div
                  class="upload_mask"
                  v-if="data.vendorExtend.enterpriseLogoRegisterImg"
                >
                  <img
                    src="../../../assets/up_close.png"
                    alt=""
                    @click="
                      closeImg(
                        'enterpriseLogoRegisterImg',
                        'enterpriseLogoRegisterImgUrl'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 商标注册证 end -->
          <!-- 品牌授权书 start -->
          <template
            v-for="(item, index) in data.vendorExtend.enterpriseBrandJsonList"
            :key="index"
          >
            <div
              class="basics_info_box"
              :style="{
                'margin-right': index % 2 != 0 && index != 0 ? `18px` : `0`,
              }"
            >
              <div class="basics_info_tit">
                <span class="xin">*</span>
                <span>品牌授权书:</span>
              </div>
              <div class="basics_info_upload">
                <div class="info_upload">
                  <div
                    class="upload_box"
                    v-if="!item.enterpriseBrandImg"
                    @click="uploadFiles('enterpriseBrandImg', index)"
                  >
                    <input
                      id="file"
                      class="filepath"
                      @change="
                        upload_img(
                          $event,
                          'enterpriseBrandImg',
                          'enterpriseBrandImgUrl',
                          index
                        )
                      "
                      :ref="'uploadFileDOM' + index"
                      type="file"
                      name="image"
                      size="1"
                      style="display: none"
                      accept="image/gif,image/jpeg,image/gif,image/png"
                    />
                    <img
                      src="../../../assets/up_jia.png"
                      alt=""
                      class="up_jia"
                    />
                    <p>上传照片</p>
                  </div>
                  <div class="upload_img" v-if="item.enterpriseBrandImg">
                    <img :src="item.enterpriseBrandImgUrl" alt="" />
                  </div>
                  <div class="upload_mask" v-if="item.enterpriseBrandImg">
                    <img
                      src="../../../assets/up_close.png"
                      alt=""
                      @click="
                        closeImg(
                          'enterpriseBrandImg',
                          'enterpriseBrandImgUrl',
                          index
                        )
                      "
                    />
                  </div>
                </div>
                <div class="brand_box">
                  <div class="brand_tit">
                    <span class="xin">*</span>
                    <span class="tit">注册时间:</span>
                  </div>
                  <el-date-picker
                    type="date"
                    v-model="item.enterpriseBrandCreateTime"
                    placeholder="选择日期"
                    :size="size"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :disabled-date="setDateRanges"
                    @focus="Datee($event, index)"
                    @change="timeadd($event, index)"
                  />
                </div>
                <div class="brand_box brand_box_one">
                  <div class="brand_tit">
                    <span class="xin">*</span>
                    <span class="tit">有效期至:</span>
                  </div>
                  <el-date-picker
                    v-model="item.enterpriseBrandEndTime"
                    type="date"
                    placeholder="选择日期"
                    :size="size"
                    :ref="'timer' + index"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :disabled-date="setDateRange"
                    @focus="Datee($event, index)"
                    @change="
                      setDatee($event, item.enterpriseBrandCreateTime, index,'zhi')
                    "
                  />
                  <el-checkbox
                    v-model="item.enterpriseBrandIsTime"
                    label="长期"
                    size="large"
                    @change="checkboxs($event, index)"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- 商标注册证 end -->
        </div>
        <div class="add_brand" @click="addBrand">追加品牌</div>
        <!-- 资质信息 end -->
      </div>
      <!-- 下一步按钮 -->
      <div class="agreement_btn" @click="nextStep">下一步</div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import areaData from "../../../assets/area.json";
import SldCommonEmpty from "@/components/SldCommonEmpty";
export default {
  name: "AccountList",
  components: { SldCommonEmpty },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const options = reactive([]);
    const areaDataArray = areaData; //地址
    const cityList_one = reactive({ data: [0] }); //企业二级地址
    const cityList_two = reactive({ data: [0] }); //注册二级地址
    const district_one = reactive({ data: [0] }); //企业三级地址
    const district_two = reactive({ data: [0] }); //注册三级地址
    const RegisterAddress = reactive({ data: ["", "", ""] }); //注册地址
    const priseAddressAll = reactive({ data: ["", "", ""] }); //企业地址
    const endTimekai = ref(-1);
    const data = reactive({
      vendorExtend: {
        enterpriseBrandJsonList: [
          {
            enterpriseBrandCreateTime: "", //品牌授权书注册时间
            enterpriseBrandEndTime: "", //品牌授权书有效期至
            enterpriseBrandImg: "", //品牌授权书（多张）
            enterpriseBrandImgUrl: "", //品牌授权书（多张）
            enterpriseBrandIsTime: false, //品牌授权书是否长期 1长期true 非长期默认0 false
          },
        ],
        enterpriseName: "", //企业名称
        enterpriseType: "公司", //企业类型
        enterpriseCode: "", //组织代码
        enterpriseAddressAll: "", //省市区组合
        enterpriseRegisterAddressAll: "", //企业注册省市区组合
        enterpriseRegisterDetailAddress: "", //	企业注册详细地址
        enterpriseBankName: "", //开户行名称
        enterpriseBankOpenAddress: "", //开户行所在地
        enterpriseBankAccount: "", //开户行账号
        enterpriseProvinceCode: "", //省份编码
        enterpriseCityCode: "", //城市编码
        enterpriseDistrictCode: "", //区县编码
        enterpriseContactName: "", //	主要联系人
        enterpriseDetailAddress: "", //企业详细地址
        enterpriseInvoiceType: "", //	开票类型
        enterpriseLegalCode: "", //法人身份证
        enterpriseLegalName: "", //法人姓名
        enterpriseMoney: "", //	注册资本
        enterpriseRange: "", //经营范围
        enterpriseRate: "", //税率
        enterpriseImg: "", //营业执照
        enterpriseLegalCodeImgBack: "", //法人身份证反面
        enterpriseLegalCodeImgFront: "", //法人身份证正面
        enterpriseLogoRegisterImg: "", //商标注册证
        enterpriseQualityImg: "", //质检报告
        enterpriseOpenAccountImg: "", //企业开户许可证
        enterpriseProduceImg: "", //企业生产许可证
        enterpriseRegisterCityCode: "", //	企业注册城市编码
        enterpriseRegisterDistrictCode: "", //企业注册区县编码
        enterpriseRegisterProvinceCode: "", //企业注册省份编码
        memberId: "", //企业会员id
        id:'',//拓展id
        vendorId:'',//vendor账号id
      },
      // applyYear: "", //开店时长
      areaInfo: "", //企业注册省市区组合
      companyAddress: "", //公司详细地址
      companyAreaCode: "", //	企业注册区编码
      companyCityCode: "", //企业注册市编码
      companyProvinceCode: "", //企业注册省编码
      contactName: "", //	联系人姓名
      contactPhone: "", //联系人电话
      enterType: "", //入驻类型
      // goodsCategoryIds: "", //申请分类id字符串,例1级-2级-3级,1级-2级-3级
      personCardDown: "", //身份证背面扫描件
      personCardUp: "", //身份证正面扫描件
      businessLicenseImage: "", //营业执照扫描件,企业入驻时必传
      // certificateId: "", //资质id,重新编辑时使用
      companyName: "", //公司名称,入驻类型为企业入驻时必传
    });

    const datas = reactive({
      vendorExtend: {
        enterpriseName: "请输入企业名称", //企业名称
        enterpriseType: "请选择企业类型", //企业类型
        enterpriseCode: "请输入组织代码", //组织代码
        enterpriseAddressAll: "请选择企业地址", //省市区组合
        enterpriseDetailAddress: "请输入企业详细地址", //企业详细地址
        enterpriseLegalName: "请输入法人姓名", //法人姓名
        enterpriseLegalCode: "请输入法人身份证", //法人身份证
        enterpriseContactName: "请输入主要联系人", //	主要联系人
        contactPhone: "请输入联系人电话", //	联系人电话
        enterpriseMoney: "请输入注册资本", //	注册资本
        enterpriseRegisterAddressAll: "请选择注册地址", //企业注册省市区组合
        enterpriseRegisterDetailAddress: "请输入注册详细地址", //	企业注册详细地址
        enterpriseRange: "请输入经营范围", //经营范围
        enterpriseBankName: "请输入开户行名称", //开户行名称
        enterpriseBankAccount: "请输入开户行账号", //开户行账号
        enterpriseBankOpenAddress: "请输入开户行所在地", //开户行所在地
        enterpriseInvoiceType: "请选择开票类型", //	开票类型
        enterpriseRate: "请输入税率", //税率
        enterpriseImg: "请上传营业执照", //营业执照
        enterpriseLegalCodeImgFront: "请上传法人身份证正面", //法人身份证正面
        enterpriseLegalCodeImgBack: "请上传法人身份证反面", //法人身份证反面
        enterpriseOpenAccountImg: "请上传开户许可证", //企业开户许可证
        enterpriseQualityImg: "请上传质检报告", //质检报告
        enterpriseProduceImg: "请上传生产许可证", //企业生产许可证
        enterpriseLogoRegisterImg: "请上传商标注册证", //商标注册证
        // enterpriseProvinceCode: "", //省份编码
        // enterpriseCityCode: "", //城市编码
        // enterpriseDistrictCode: "", //区县编码
        // enterpriseRegisterCityCode: "", //	企业注册城市编码
        // enterpriseRegisterDistrictCode: "", //企业注册区县编码
        // enterpriseRegisterProvinceCode: "", //企业注册省份编码
      },
    });

    const enterImg = reactive({
      enterpriseImgUrl: "", //企业营业执照
      enterpriseLegalCodeImgBackUrl: "", //法人身份证反面
      enterpriseLegalCodeImgFrontUrl: "", //法人身份证正面
      enterpriseLogoRegisterImgUrl: "", //商标注册证
      enterpriseQualityImgUrl: "", //质检报告
      enterpriseOpenAccountImgUrl: "", //企业开户许可证
      enterpriseProduceImgUrl: "", //企业生产许可证
      enterpriseBrandJsonListUrl: [
        {
          img: "",
        },
      ], //品牌授权json list
    });

    const companyList = reactive([
      //公司类型
      {
        value: "公司",
      },
      {
        value: "个人",
      },
    ]);

    const invoiceType = reactive([
      //发票类型
      {
        value: "普通发票",
      },
      {
        value: "增值税专票",
      },
    ]);

    const addflag = ref(0); //地址栏判断
    const cityFlag_o = ref(null); //二级地址栏判断
    const distFlag_o = ref(null); //三级地址栏判断
    const cityFlag_t = ref(null); //二级地址栏判断
    const distFlag_t = ref(null); //三级地址栏判断

    const uploadFileDOM = ref(null); //ref上传图片
    const uploadFileDOMa = ref(null); //ref上传图片
    const uploadFileDOMb = ref(null); //ref上传图片
    const uploadFileDOMc = ref(null); //ref上传图片
    const uploadFileDOMd = ref(null); //ref上传图片
    const uploadFileDOMe = ref(null); //ref上传图片
    const uploadFileDOMf = ref(null); //ref上传图片
    const uploadFileDOMg = ref(null); //ref上传图片
    const uploadFileDOMh = ref(null); //ref上传图片

    // 唤起上传
    const uploadFile = (flag) => {
      if (flag == "enterpriseImg") {
        uploadFileDOM.value.click();
      }
      if (flag == "enterpriseLegalCodeImgFront") {
        uploadFileDOMa.value.click();
      }
      if (flag == "enterpriseLegalCodeImgBack") {
        uploadFileDOMb.value.click();
      }
      if (flag == "enterpriseOpenAccountImg") {
        uploadFileDOMc.value.click();
      }
      if (flag == "enterpriseQualityImg") {
        uploadFileDOMd.value.click();
      }
      if (flag == "enterpriseProduceImg") {
        uploadFileDOMe.value.click();
      }
      if (flag == "enterpriseLogoRegisterImg") {
        uploadFileDOMf.value.click();
      }
      if (flag == "enterpriseBrandImg") {
        uploadFileDOMg.value.click();
      }
    };

    //唤起上传
    const uploadFiles = (name, index) => {
      const VideoDOM = "uploadFileDOM" + index;
      proxy.$refs[VideoDOM].click();
    };

    // 上传图片事件
    const upload_img = (e, path, img, ind) => {
      let datas = new FormData();
      datas.append("file", e.target.files[0]);
      datas.append("source", "headImg");
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let url = apiUrl + "/v3/oss/common/upload";
      if (e.target.files[0].size < Math.pow(1024, 2) * 20) {
        axios.post(url, datas, config).then((res) => {
          if (res.status === 200) {
            ElMessage.success("上传图片成功");
            if (path == "enterpriseBrandImg") {
              data.vendorExtend.enterpriseBrandJsonList[
                ind
              ].enterpriseBrandImg = res.data.data.path;
              data.vendorExtend.enterpriseBrandJsonList[
                ind
              ].enterpriseBrandImgUrl = res.data.data.url;
            } else {
              enterImg[img] = res.data.data.url;
              data.vendorExtend[path] = res.data.data.path;
            }
          } else {
            ElMessage.warning("上传图片失败");
          }
        });
      } else {
        ElMessage.warning("图像应小于20MB");
        tempSrc.value = avatar.value;
      }
    };

    //删除图片
    const closeImg = (path, img, ind) => {
      if (path == "enterpriseBrandImg") {
        data.vendorExtend.enterpriseBrandJsonList[ind].enterpriseBrandImg = "";
        data.vendorExtend.enterpriseBrandJsonList[ind].enterpriseBrandImgUrl =
          "";
      } else {
        enterImg[img] = "";
        data.vendorExtend[path] = "";
      }
    };

    //企业地址
    const enterpriseAddress = reactive({
      province: "",
      city: "",
      distric: "",
    });

    //一级地址
    const adderssd = (flag, e) => {
      addflag.value = e;
      if (e == 1 && flag == 0) {
        // 获取地址一级地址index
        let index = areaData.findIndex((item) => {
          return item.regionCode == data.vendorExtend.enterpriseProvinceCode;
        });
        data.vendorExtend.enterpriseAddressAll = ""; //省市区组合置空
        cityList_one.data = []; //二级地址清空
        priseAddressAll[0] = ""; //放地址的数组清空
        district_one.data = [0];
        priseAddressAll[1] = ""; //放地址的数组清空
        priseAddressAll[2] = ""; //放地址的数组清空
        data.vendorExtend.enterpriseCityCode = ""; //城市编码置空
        data.vendorExtend.enterpriseDistrictCode = ""; //县编码置空
        cityList_one.data = areaData[index].children; //获取二级地址
        priseAddressAll[0] = areaData[index].regionName; //获取一级地址名字
      }
      if (e == 1 && flag == 1) {
        let index = cityList_one.data.findIndex((item) => {
          return item.regionCode == data.vendorExtend.enterpriseCityCode;
        });
        data.vendorExtend.enterpriseAddressAll = "";
        district_one.data = [];
        priseAddressAll[1] = "";
        priseAddressAll[2] = "";
        data.vendorExtend.enterpriseDistrictCode = "";
        district_one.data = cityList_one.data[index].children;
        priseAddressAll[1] = cityList_one.data[index].regionName;
      }
      if (e == 1 && flag == 2) {
        let index = district_one.data.findIndex((item) => {
          return item.regionCode == data.vendorExtend.enterpriseDistrictCode;
        });
        data.vendorExtend.enterpriseAddressAll = "";
        priseAddressAll[2] = district_one.data[index].regionName;
        // 获取省市区组合
        data.vendorExtend.enterpriseAddressAll =
          priseAddressAll[0] + priseAddressAll[1] + priseAddressAll[2];
      }
      if (e == 2 && flag == 0) {
        let index = areaData.findIndex((item) => {
          return (
            item.regionCode == data.vendorExtend.enterpriseRegisterProvinceCode
          );
        });
        data.vendorExtend.enterpriseRegisterAddressAll = "";
        cityList_two.data = [];
        RegisterAddress[0] = "";
        district_two.data = [0];
        RegisterAddress[1] = "";
        RegisterAddress[2] = "";
        data.vendorExtend.enterpriseRegisterCityCode = "";
        data.vendorExtend.enterpriseRegisterDistrictCode = "";
        cityList_two.data = areaData[index].children;
        RegisterAddress[0] = areaData[index].regionName;
      }
      if (e == 2 && flag == 1) {
        let index = cityList_two.data.findIndex((item) => {
          return (
            item.regionCode == data.vendorExtend.enterpriseRegisterCityCode
          );
        });
        data.vendorExtend.enterpriseRegisterAddressAll = "";
        district_two.data = [];
        RegisterAddress[1] = "";
        RegisterAddress[2] = "";
        data.vendorExtend.enterpriseRegisterDistrictCode = "";
        district_two.data = cityList_two.data[index].children;
        RegisterAddress[1] = cityList_two.data[index].regionName;
      }
      if (e == 2 && flag == 2) {
        let index = district_two.data.findIndex((item) => {
          return (
            item.regionCode == data.vendorExtend.enterpriseRegisterDistrictCode
          );
        });
        data.vendorExtend.enterpriseRegisterAddressAll = "";
        RegisterAddress[2] = district_two.data[index].regionName;
        data.vendorExtend.enterpriseRegisterAddressAll =
          RegisterAddress[0] + RegisterAddress[1] + RegisterAddress[2];
      }
    };

    //二级地址
    const addCity = (e, flag) => {
      if (e == true) {
        if (flag == 1) {
          if (cityList_one.data[0] == 0 || cityList_one.data.length == 0) {
            ElMessage.warning("请选择省级地址");
            cityFlag_o.value.blur();
            return;
          }
        }
        if (flag == 2) {
          if (cityList_two.data[0] == 0 || cityList_two.data.length == 0) {
            ElMessage.warning("请选择省级地址");
            cityFlag_t.value.blur();
            return;
          }
        }
      }
    };

    //三级地址
    const addDistrict = (e, flag) => {
      if (e == true) {
        if (flag == 1) {
          if (cityList_one.data[0] == 0 || cityList_one.data.length == 0) {
            ElMessage.warning("请选择省级地址");
            distFlag_o.value.blur();
            return;
          }
          if (district_one.data[0] == 0 || district_one.data.length == 0) {
            ElMessage.warning("请选择市级地址");
            distFlag_o.value.blur();
            return;
          }
        }
        if (flag == 2) {
          if (cityList_two.data[0] == 0 || cityList_two.data.length == 0) {
            ElMessage.warning("请选择省级地址");
            distFlag_t.value.blur();
            return;
          }
          if (district_two.data[0] == 0 || district_two.data.length == 0) {
            ElMessage.warning("请选择市级地址");
            distFlag_t.value.blur();
            return;
          }
        }
      }
    };

    //品牌授权书日期比较
    const timeadd = (e, ind) => {
      if (e) {
        let timeshu = e.split("-");
        endTimekai.value = ind;
      } else {
        data.vendorExtend.enterpriseBrandJsonList[
          ind
        ].enterpriseBrandCreateTime = "";
        data.vendorExtend.enterpriseBrandJsonList[ind].enterpriseBrandEndTime =
          "";
      }
    };

    onMounted(() => {
      // getEnterprise()
      getDetail();
    });

    //获取入驻详情接口
    const getDetail = () => {
      if (JSON.parse(localStorage.getItem("saveApply1"))) {
        let obj = JSON.parse(localStorage.getItem("saveApply1"))
        data.areaInfo=obj.areaInfo; //企业注册省市区组合
        data.companyAddress= obj.companyAddress; //公司详细地址
        data.companyAreaCode= obj.companyAreaCode; //	企业注册区编码
        data.companyCityCode= obj.companyCityCode; //企业注册市编码
        data.companyProvinceCode= obj.companyProvinceCode; //企业注册省编码
        data.contactName= obj.contactName; //	联系人姓名
        data.contactPhone= obj.contactPhone;
        data.enterType= obj.enterType; 
      // goodsCategoryIds= obj.; 
      data.personCardDown= obj.personCardDown; 
      data.personCardUp= obj.personCardUp; 
      data.businessLicenseImage= obj.businessLicenseImage;
      // certificateId= obj.;
      data.companyName= obj.companyName; 
      data.memberId= obj.memberId; 
      data.vendorExtend = obj.vendorExtend;
      getcitya(JSON.parse(JSON.stringify(obj.vendorExtend)),1)
      getcitya(JSON.parse(JSON.stringify(obj.vendorExtend)),2)
      for(var i in enterImg){
        enterImg[i] = JSON.parse(localStorage.getItem("saveApply_img"))[i]
      }
      } else {
        proxy.$get("v3/member/front/member/applyDetail").then((res) => {
          if (res.state == 200) {
            // console.log(data.vendorExtend);
            let obj = res.data.vendorExtend;
            if(res.data.contactPhone){
              // console.log(2222);
              data.contactPhone= res.data.contactPhone; 
            }
            for (var i in data.vendorExtend) {
              if (i == "enterpriseLegalCodeImgBack"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseLegalCodeImgBackUrl = obj.enterpriseLegalCodeImgBackUrl;
              } else if (i == "enterpriseLegalCodeImgFront"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseLegalCodeImgFrontUrl = obj.enterpriseLegalCodeImgFrontUrl;
              }else if (i == "enterpriseImg"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseImgUrl = obj.enterpriseImgUrl;
              }else if (i == "enterpriseOpenAccountImg"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseOpenAccountImgUrl = obj.enterpriseOpenAccountImgUrl;
              }else if (i == "enterpriseQualityImg"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseQualityImgUrl = obj.enterpriseQualityImgUrl;
              }else if (i == "enterpriseProduceImg"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseProduceImgUrl = obj.enterpriseProduceImgUrl;
              }else if (i == "enterpriseLogoRegisterImg"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                data.vendorExtend[i] = obj[i];
                enterImg.enterpriseLogoRegisterImgUrl = obj.enterpriseLogoRegisterImgUrl;
              }else if (i == "enterpriseCityCode"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                
              }else if (i == "enterpriseBrandJsonList"&&!(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0)) {
                if(obj.enterpriseBrandJsonList){
                  let JsonList =JSON.parse(obj.enterpriseBrandJsonList)
                  JsonList.forEach(item=>{
                    if(item.enterpriseBrandIsTime==1){
                      item.enterpriseBrandIsTime = true
                    }else{
                      item.enterpriseBrandIsTime = false
                    }
                  })
                  data.vendorExtend.enterpriseBrandJsonList = JsonList

                }
              }else {
                if(!obj[i] && typeof(obj[i]) !== 'undefined' && obj[i] != 0){
                  if(i == "enterpriseBrandJsonList"){
                    // data.vendorExtend[i] = []
                  }else{
                    data.vendorExtend[i] = ''

                  }
                }else{
                  data.vendorExtend[i] = obj[i];
                }
              }
            }
            console.log( data.vendorExtend);
            if(obj.enterpriseEmail){
              localStorage.setItem('saveApply3',obj.enterpriseEmail)
            }
            setTimeout(()=>{
              getcitya(obj,1)
              getcitya(obj,2)
            })
          }
        });
      }
    };
    const getcitya = (obj,flag)=>{
      if(flag==1){
        if (data.vendorExtend.enterpriseProvinceCode) {
            adderssd(0, 1);
            // console.log(obj);
            data.vendorExtend.enterpriseCityCode = obj.enterpriseCityCode
            if (data.vendorExtend.enterpriseCityCode) {
              adderssd(1, 1);
              data.vendorExtend.enterpriseDistrictCode = obj.enterpriseDistrictCode
              if (data.vendorExtend.enterpriseDistrictCode) {
                adderssd(2, 1);
              }
            }
          }
      }else{
        if (data.vendorExtend.enterpriseRegisterProvinceCode) {
            adderssd(0, 2);
            data.vendorExtend.enterpriseRegisterCityCode = obj.enterpriseRegisterCityCode
            if (data.vendorExtend.enterpriseRegisterCityCode) {
              adderssd(1, 2);
              data.vendorExtend.enterpriseRegisterDistrictCode = obj.enterpriseRegisterDistrictCode
              if (data.vendorExtend.enterpriseRegisterDistrictCode) {
                adderssd(2, 2);
              }
            }
          }
      }
    }

    //  下一步按钮事件
    const nextStep = () => {
      let param = {};
      let obj = data.vendorExtend;
      for (let it in datas.vendorExtend) {
        if (it != "contactPhone" && obj[it].length == 0&&(!obj[it] && typeof(obj[it]) !== 'undefined' && obj[it] != 0)) {
          ElMessage.warning(datas.vendorExtend[it]);
          return;
        }
        if (it != "contactPhone" && obj[it].trim().length == 0&&!obj[it]) {
          ElMessage.warning(datas.vendorExtend[it]);
          return;
        }
        if (it == "contactPhone" && data.contactPhone.length == 0) {
          ElMessage.warning(datas.vendorExtend[it]);
          return;
        }
        if(it=='contactPhone'&&proxy.$checkPhone(data.contactPhone,'请填写正确的联系人电话')!=true){
          ElMessage.warning(proxy.$checkPhone(data.contactPhone, '请填写正确的联系人电话'))
          return
        }
      }
      for (
        let i = 0;
        i < data.vendorExtend.enterpriseBrandJsonList.length;
        i++
      ) {
        let item = data.vendorExtend.enterpriseBrandJsonList[i];
        if (!item.enterpriseBrandImg) {
          ElMessage.warning("请上传品牌授权书");
          return;
        }
        if (!item.enterpriseBrandCreateTime) {
            ElMessage.warning("请选择品牌授权书注册时间");
            return;
          }
        if (item.enterpriseBrandIsTime == false) {
          // if (!item.enterpriseBrandCreateTime) {
          //   ElMessage.warning("请选择品牌授权书注册时间");
          //   return;
          // }
          if (!item.enterpriseBrandEndTime) {
            ElMessage.warning("请选择品牌授权书有效期");
            return;
          }
        }
      }
      data.areaInfo = data.vendorExtend.enterpriseRegisterAddressAll;
      data.companyAddress = data.vendorExtend.enterpriseRegisterDetailAddress;
      data.companyProvinceCode = data.vendorExtend.enterpriseProvinceCode;
      data.companyCityCode = data.vendorExtend.enterpriseCityCode;
      data.companyAreaCode = data.vendorExtend.enterpriseDistrictCode;
      data.personCardUp = data.vendorExtend.enterpriseLegalCodeImgFront;
      data.contactName = data.vendorExtend.enterpriseContactName;
      data.personCardDown = data.vendorExtend.enterpriseLegalCodeImgBack;
      data.enterType = data.vendorExtend.enterpriseType == "公司" ? 1 : 0;
      if (data.enterType == 1) {
        data.companyName = data.vendorExtend.enterpriseName;
        data.businessLicenseImage = data.vendorExtend.enterpriseImg;
      }
      localStorage.setItem("saveApply1", JSON.stringify(data));
      localStorage.setItem("saveApply_img", JSON.stringify(enterImg));
      router.push({
        path: "/member/settle/storeInformation",
      });
    };
    // 追加品牌
    const addBrand = () => {
      let brandInfo = {
        enterpriseBrandCreateTime: "", //品牌授权书注册时间
        enterpriseBrandEndTime: "", //品牌授权书截至时间
        enterpriseBrandImg: "", //品牌授权书（多张）
        enterpriseBrandIsTime: false, //品牌授权书是否长期 1长期 0 非长期默认0
      };
      data.vendorExtend.enterpriseBrandJsonList.push(brandInfo);
    };

    //有效期日期范围
    const setDateRange = (time) => {
      if (endTimekai.value >= 0) {
        return (
          time.getTime() <
          new Date(
            data.vendorExtend.enterpriseBrandJsonList[
              endTimekai.value
            ].enterpriseBrandCreateTime
          ).getTime() +
            4.64e7
        );
      }
    };
    //有效期日期范围
    const setDateRanges = (time) => {
      return time.getTime() > Date.now()
      // if (endTimekai.value >= 0) {
      //   return (
      //     time.getTime() <
      //     new Date(
      //       data.vendorExtend.enterpriseBrandJsonList[
      //         endTimekai.value
      //       ].enterpriseBrandCreateTime
      //     ).getTime() +
      //       4.64e7
      //   );
      // }
    };

    //当注册时间没有选时不能选有效期
    const setDatee = (flag, ind, int) => {
      if (ind.length == 0) {
        data.vendorExtend.enterpriseBrandJsonList[int].enterpriseBrandEndTime =
          "";
        ElMessage.warning("请选择注册时间");
      }
      if(flag){
        data.vendorExtend.enterpriseBrandJsonList[int].enterpriseBrandIsTime = false
      }
      if (!flag) {
        data.vendorExtend.enterpriseBrandJsonList[int].enterpriseBrandEndTime =
          "";
      }
    };
    const checkboxs = (e,ind)=>{
      if(e){
        // data.vendorExtend.enterpriseBrandJsonList[
        //   ind
        // ].enterpriseBrandCreateTime = "";
        data.vendorExtend.enterpriseBrandJsonList[ind].enterpriseBrandEndTime =
          "";
      }
    }

    //data.vendorExtend.enterpriseBrandJsonList的索引
    const Datee = (e, index) => {
      endTimekai.value = index;
    };

    const getEnterprise = () => {
      // let saveApply = JSON.parse(saveApply)
    };

    const inpinfo = (e)=>{
      let ret=/^\d+(\.\d{1,2})?$/;
      if(!ret.test(data.vendorExtend.enterpriseMoney)){
        let tempVal = Number(data.vendorExtend.enterpriseMoney).toFixed(3)
        data.vendorExtend.enterpriseMoney = tempVal.substring(0, tempVal.length - 1)
        return false;
      }
      
    }

    return {
      L,
      setDatee,
      Datee,
      options,
      nextStep,
      addBrand,
      data,
      companyList,
      enterpriseAddress,
      areaDataArray,
      adderssd,
      addCity,
      addDistrict,
      cityList_one,
      cityList_two,
      district_one,
      district_two,
      addflag,
      cityFlag_o,
      distFlag_o,
      cityFlag_t,
      distFlag_t,
      invoiceType,
      uploadFileDOM,
      uploadFileDOMa,
      uploadFileDOMb,
      uploadFileDOMc,
      uploadFileDOMd,
      uploadFileDOMe,
      uploadFileDOMf,
      uploadFileDOMg,
      uploadFileDOMh,
      uploadFile,
      upload_img,
      enterImg,
      closeImg,
      uploadFiles,
      RegisterAddress,
      priseAddressAll,
      timeadd,
      setDateRange,
      setDateRanges,
      endTimekai,
      datas,
      checkboxs,
      inpinfo
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/enterprise.scss";
</style>
<style lang="scss">
.sld_settle_enterprise {
  .basics_info_sel {
    .el-select {
    }
  }
  .sel_info {
    .el-select {
      width: 330px;
    }
  }
  .basics_dizhi {
    .el-select {
      width: 261px;
    }
  }
  .basics_di {
    .el-select {
      margin-right: 20px;
      width: 261px;
    }
  }
  .brand_box {
    .el-input--prefix .el-input__inner {
      height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 211px;
    }
  }
  .brand_box_one {
    display: flex;
    align-items: center;
    .el-input--prefix .el-input__inner {
      height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 148px;
    }
    .el-checkbox {
      margin-left: 13px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.sld_settle_enterprise {
  .el-input__inner {
    border-color: #c0c4cc42 !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #f30213;
    border-color: #f30213;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #999;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: rgb(220, 223, 230);
  }

  /* Chrome */
::-webkit-input-placeholder{
  color: #c0c4cc;
}
/* IE 10+ */
:-ms-input-placeholder{
  color: #c0c4cc;
}
/* Firefox 4-18 */
/* Firefox 19+ */
:-moz-placeholder,
::-moz-placeholder{
  color: #c0c4cc;
  opacity: 1;
}
}
</style> 
